import { useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import get from 'lodash/get'
import AppContext from '../src/AppContext'
import LoadingPage from '../src/LoadingPage'
import { useCaptureAuthError } from '../src/auth/useCaptureAuthError'

export default function Root() {
  const { currentUser } = useContext(AppContext)
  const { replace } = useRouter()

  useCaptureAuthError()

  useEffect(() => {
    if (currentUser) {
      const lastVisitedTeamSlug = Cookies.get(`${currentUser.id}_lastVisitedTeam`)
      const allTeams = get(currentUser, 'members.nodes', []).map((node) => node.team.slug)

      if (lastVisitedTeamSlug && allTeams.includes(lastVisitedTeamSlug)) {
        return replace(lastVisitedTeamSlug)
      } else {
        return replace(currentUser.personalTeam.slug)
      }
    }
  }, [currentUser])

  return <LoadingPage />
}
