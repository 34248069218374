import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { omit } from 'lodash'
import { useNotifications } from '../util'

export const useCaptureAuthError = () => {
  const notifications = useNotifications()
  const { query, replace, pathname } = useRouter()

  useEffect(() => {
    if (query.err) {
      notifications.error(query.err, { preventDuplicate: true })
      replace({ pathname, query: omit(query, ['err']) }, null, { shallow: true })
    }
  }, [query.err])
}
